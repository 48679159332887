import { render, staticRenderFns } from "./pesan.vue?vue&type=template&id=0d46f3ff"
import script from "./pesan.vue?vue&type=script&lang=js"
export * from "./pesan.vue?vue&type=script&lang=js"
import style0 from "./pesan.vue?vue&type=style&index=0&id=0d46f3ff&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports